import { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import { Toaster } from 'react-hot-toast';
import ForgotPassword from './pages/ForgotPassword';
import Login from "./pages/Login";
import BrokerPath from './BrokerPath';
import Home from './pages/Dashboard/Home';
import NewInvestors from './pages/Dashboard/NewInvestors';
import ExistingInvestors from './pages/Dashboard/ExistingInvestors';
import AllTradeRequests from './pages/Dashboard/AllTradeRequests';
import Investor from './pages/Dashboard/Investor';
import SingleInvestor from './pages/Dashboard/SingleInvestor';
import SingleTradeRequest from './pages/Dashboard/SingleTradeRequest';
import NewBroker from './pages/Dashboard/NewBroker';
import ExistingBrokers from './pages/Dashboard/ExistingBrokers';
import SingleBroker from './pages/Dashboard/SingleBroker';
import Profile from './pages/Dashboard/Profile';
import Contact from './pages/Dashboard/Contact';
import Messages from './pages/Dashboard/Messages';
import ChangePassword from './pages/Dashboard/ChangePassword';
import Preloader from './components/Preloader';
import AdminTradeRequests from './pages/Dashboard/AdminTradeRequests';
import DeclinedInvestors from './pages/Dashboard/DeclinedInvestors';
import DeclinedInvestor from './pages/Dashboard/DeclinedInvestor';
import BrokerDeclinedInvestors from './pages/Dashboard/BrokerDeclinedInvestors';
import AllOrders from './pages/Dashboard/AllOrders';

function App() {
  return (
    <div>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route
            path="/dashboard"
            element={<Navigate replace to="/dashboard/home" />}
          />
          <Route path="/dashboard" element={<BrokerPath />}>
            <Route path="home" element={<Home />} />

            {/* Investors Routes */}
            <Route path="new-investors" element={<NewInvestors />} />
            <Route path="existing-investors" element={<ExistingInvestors />} />
            <Route path="investor/:id" element={<SingleInvestor />} />
            <Route path="declined-investor/:id" element={<DeclinedInvestor />} />
            <Route path="broker-declined-investors" element={<BrokerDeclinedInvestors />} />

            {/* Order Routes */}
            <Route path="broker-orders/:brokerCode" element={<AllOrders />} />

            {/* Trade Routes */}
            <Route path="all-trade-requests" element={<AllTradeRequests />} />
            <Route path="trade-request/:id" element={<SingleTradeRequest />} />

            {/* Brokers Account Route */}
            <Route path="new-broker" element={<NewBroker />} />
            <Route path="view-all-trades" element={<AdminTradeRequests />} />
            <Route path="existing-brokers" element={<ExistingBrokers />} />
            <Route path="existing-brokers/:id" element={<SingleBroker />} />

            {/* Users profile */}
            <Route path="profile/:id" element={<Profile />} />

            {/* Contact NASD */}
            <Route path="contact-us" element={<Contact />} />

            {/* Broker Messages */}
            <Route path="broker-messages" element={<Messages />} />

            {/* Broker Changes Password */}
            <Route path="change-password" element={<ChangePassword />} />

            {/* Declined Investors */}
            <Route path="declined-investors" element={<DeclinedInvestors />} />
          </Route>
        </Routes>
      </Suspense>
      <Toaster />
    </div>
  );
}

export default App;
