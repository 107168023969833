import React from 'react';
import  { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import { get_all_brokers_account } from '../../redux/actions/authActions';
import Meta from '../../components/Meta';
import Preloader from '../../components/Preloader';
import DeclineInvestorTable from '../../components/Tables/DeclinedInvestorTable';
import { get_all_declined_investors } from '../../redux/actions/investorsActions';
import { get_all_individual_investors,
} from '../../redux/actions/investorsActions';

const DeclinedInvestors = () => {
    const dispatch = useDispatch();
    const tableRef = React.useRef();

    const [ selected, setSelected ] = React.useState();

    const broker = useSelector((state) => state.broker);
    const { brokers } = broker;

    const declinedInvestors = useSelector((state) => state.declinedInvestors);
    const { allDeclinedInvestors } = declinedInvestors;

    const individual = useSelector((state) => state.individual);
    const {loading, indivInvestor } = individual;

    const getBrokers = () => {
        if(brokers && brokers.length > 0){
            return brokers.filter(brok => brok.type === 1)
        }
    };

    const getFullData = () => {
        let data = [];

        if(allDeclinedInvestors && allDeclinedInvestors.data.declinedInvestors.length > 0){
            allDeclinedInvestors.data.declinedInvestors.map((a, i) => {
                indivInvestor && indivInvestor.map((b, i) => {
                    if(a.investorId === b.id) {
                        let details = {};

                        details.emailAddress = b.emailAddress;
                        details.id = b.id;
                        details.fullName = b.fullName;
                        details.phoneNumber = b.phoneNumber;
                        details.brokername = b.brokername;
                        details.brokeremail = b.brokeremail;
                        details.brokerphone = b.brokerphone;

                        return data.push(details)
                    }
                })
            });
        }
        return data
    }

    const fullData = getFullData();
    const data = getBrokers();
    const filterHandler = (e) => {
        e.preventDefault()
		// dispatch(
		// 	get_declined_investors(
		// 		selected,
		// 	)
		// );
    };

    React.useEffect(() => {
        dispatch(get_all_brokers_account());
        dispatch(get_all_declined_investors());
        dispatch(get_all_individual_investors());
    }, []);

    return (
        <section className="section">
            <Meta title="All Declined Investors" />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="title mb-30">
                                <h2>Declined Investors</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                                
                        </div>
                    </div>
                </div>
                
                <Card border='light' className='p-0 mb-4'>
                    <Card.Body>
                        <Row>
                            
                            <Col lg={6}>
                                <div className="input-style-4 d-flex justify-content-start">
                                    <select 
                                        value={selected}
                                        onChange={(e) => setSelected(e.target.value)}
                                    >
                                        <option>Filter Investors By Broker</option>
                                        {
                                            data && data.map((broker, index) => (
                                                <option  key={`broker-${index}`} value={broker.name}>{broker.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}></Col>
                        </Row>
                    </Card.Body>
                </Card>

                {
                    loading ? (
                        <Preloader />
                    ) : (
                    <Card border='light' className='my-5 '>
                        <Card.Body>
                            {
                                fullData &&
                                    fullData.length > 0 ? (
                                        <DeclineInvestorTable entries={fullData} reference={tableRef}/>
                                ) :  (
                                    <h6
                                        style={{ color: "#252063" }}
                                    >There are no investors declined from trading.</h6>
                                )
                            }
                        </Card.Body>
                    </Card>
                    )
                }    
            </div>
        </section>
    )
}

export default DeclinedInvestors