import { Row, Col, Card, Form, ButtonGroup } from '@themesberg/react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { AiOutlineEye } from "react-icons/ai"
import { FiTrash2 } from "react-icons/fi"
import { useNavigate } from "react-router-dom";
import ExportCSV from '../ExportCSV';
import React from 'react';


const OrdersTable = ({ entries, ref }) => {
    const navigate= useNavigate();

    const singleTradeDetails = () => {
        navigate(`/dashboard/trade-request/56`)
    }

    const actionBtn = () => {
        return(
            <div className='trade-btns'>
                <button className="btn"
                    style={{ backgroundColor: "#252063", color: "#ffffff"}}
                    onClick={singleTradeDetails}
                >
                    <AiOutlineEye />
                </button>
            </div>
        )
    }

    const formatDate = (cell) => {
		if (!cell) return ' '

		let date = new Date(cell)

		return (
			date.getDate() +
			'-' +
			(date.getMonth() + 1) +
			'-' +
			date.getFullYear() +
			'' +
			' [' +
			date.getHours() +
			':' +
			date.getMinutes() +
            ']'
		)
	}

    const columns = [
        { dataField: "id", text: "ID", hidden: true },
        { dataField: "clientName", text: "Client Name", sort: true },
        { dataField: "accountNo", text: "Account Number", sort: true },
        { dataField: "security", text: "Security", sort: true },
        { dataField: "orderType", text: "Order Type", sort: true},
        { dataField: "basePrice", text: "Price", sort: true},
        { dataField: "units", text: "Units", sort: true},
        { dataField: "status", text: "Status", sort: true},
        { dataField: "updatedAt", text: "Date [Time]", formatter: (cell) => formatDate(cell)}
      ];
      
      const customTotal = (from, to, size) => (
        <div>
          Showing {from} to {to} of {size} entries
        </div>
      );
      
      const customSizePerPage = (props) => {
        const { options, currentSizePerPage, onSizePerPageChange } = props;
      
        const onPageChange = (e) => {
          const page = e.target.value;
          onSizePerPageChange(page);
        }
      
        return (
          <Row>
            <Col xs="auto">
              <Form.Select value={currentSizePerPage} onChange={onPageChange} className="pe-5">
                {options.map(o => (
                  <option key={o.page} value={o.page}>
                    {o.text}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              entries per page
            </Col>
          </Row>
        );
      };
      
      return(
        <ToolkitProvider
          keyField="id"
          search={true}
          columns={columns}
          data={entries}
          wrapperClasses="table-responsive"
        >
          {({ baseProps, searchProps, csvProps }) => (
            <>
            <div className="d-flex justify-content-end align-items-center mb-2">
                  <div className="d-flex ml-auto my-3">
                      <ButtonGroup>
                          <ExportCSV
                            variant="outline-secondary" 
                            size="sm" 
                            className="px-3"
                            { ...csvProps }
                          >
                              Export CSV
                          </ExportCSV>
                          <ExportCSV
                            variant="outline-secondary" 
                            size="sm" 
                            className="px-3"
                          >
                            Export PDF
                          </ExportCSV>
                      </ButtonGroup>
                  </div>
              </div>

            <Paginator.PaginationProvider pagination={
              Pagination({
                custom: true,
                showTotal: true,
                alwaysShowAllBtns: true,
                totalSize: entries.length,
                paginationTotalRenderer: customTotal,
                sizePerPageRenderer: customSizePerPage
              })
            }>
              {({ paginationProps, paginationTableProps }) => (
                <Card>
                  <div className="table-responsive py-2">
                    <Card.Header>
                      <Row>
                        <Col xs={12} md={6} className="py-1">
                          <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-md-end py-1">
                          <Search.SearchBar {...searchProps} />
                        </Col>
                      </Row>
                    </Card.Header>
      
                    <BootstrapTable
                      ref={ref} 
                      bootstrap4
                      {...baseProps} 
                      {...paginationTableProps} 
                      bodyClasses="border-1" 
                      rowClasses="border-bottom"
                      classes="table-bordered dataTable-table table-striped"
                    />
      
                    <Card.Footer>
                      <Row>
                        <Col xs={12} md={6} className="d-flex align-items-center py-1">
                          <Paginator.PaginationTotalStandalone {...paginationProps} />
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                        >
                          <Paginator.PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </Card.Footer>
                  </div>
                </Card>
              )}
            </Paginator.PaginationProvider>
            </>
          )}
        </ToolkitProvider>
      );
}

export default OrdersTable