import * as types from "../types/index"

export const investorTradesReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case types.ALL_INVESTOR_TRADE_REQUEST:
            return { ...state, loading: true };
        case types.ALL_INVESTOR_TRADE_SUCCESS:
            return { loading: false, investorTrades: action.payload };
        case types.ALL_INVESTOR_TRADE_FAIL:
            return { loading: false, error: action.payload };
        case types.ALL_INVESTOR_TRADE_RESET:
            return {};
        default:
            return state;
    }
}

export const investorSellReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case types.ALL_INVESTOR_SELL_TRADE_REQUEST:
            return { ...state, loading: true };
        case types.ALL_INVESTOR_SELL_TRADE_SUCCESS:
            return { loading: false, saleTrades: action.payload };
        case types.ALL_INVESTOR_SELL_TRADE_FAIL:
            return { loading: false, error: action.payload };
        case types.ALL_INVESTOR_SELL_TRADE_RESET:
            return {};
        default:
            return state;
    }
}

export const adminViewTradesReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case types.ADMIN_INVESTOR_TRADE_REQUEST:
            return { ...state, loading: true };
        case types.ADMIN_INVESTOR_TRADE_SUCCESS:
            return { loading: false, adminViewTrades: action.payload };
        case types.ADMIN_INVESTOR_TRADE_FAIL:
            return { loading: false, error: action.payload };
        case types.ADMIN_INVESTOR_TRADE_RESET:
            return {};
        default:
            return state;
    }
}