import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Badge, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faEllipsisH, faFileDownload, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ExportCSV from "../ExportCSV";
import ModalComponent from '../ModalComponent'



const DeclineInvestorTable = ({ entries, reference }) => {
    const [ openModal, setOpenModal ] = useState(false);
    const [ reason, setReason ] = useState("")
    const [ show, setShow ] = useState(false)

    const navigate = useNavigate();

    const toggleModal = () => {
      setOpenModal(!openModal)
    }

    const handleDecline = () => {}

    const actionBtn = (cell) => {
        return (
          <div className="dropdown">
            <span className="dropbtn">
                <Link to={`/dashboard/investor/${cell}`}>
                    <FontAwesomeIcon icon={faEye} 
                        size={12}
                        className="me-2" 
                        style={{ color:"black", fontSize:".8rem"}}
                    />
                </Link>
            </span>
          </div>
        )
    }

    const columns = [
        { dataField: "id", text: "ID", hidden: true },
        { dataField: "fullName", text: "Investor Name", sort: true },
        { dataField: "emailAddress", text: "Investor Email", sort: true },
        { dataField: "phoneNumber", text: "Investor Phone", sort: true },
        { dataField: "brokername", text: "Broker Name", sort: true },
        { dataField: "brokeremail", text: "Broker Email", sort: true },
        { dataField: "brokerphone", text: "Broker Phone", sort: true },
        { dataField: "id", text: "Actions", formatter: (cell) =>  actionBtn(cell)}
      ];
      
      const customTotal = (from, to, size) => (
        <div>
          Showing {from} to {to} of {size} entries
        </div>
      );
      
      const customSizePerPage = (props) => {
        const { options, currentSizePerPage, onSizePerPageChange } = props;
      
        const onPageChange = (e) => {
          const page = e.target.value;
          onSizePerPageChange(page);
        }
      
        return (
          <Row>
            <Col xs="auto">
              <Form.Select value={currentSizePerPage} onChange={onPageChange} className="pe-5">
                {options.map(o => (
                  <option key={o.page} value={o.page}>
                    {o.text}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              entries per page
            </Col>
          </Row>
        );
      };
      
      return(
        <>
          <ToolkitProvider
            keyField="id"
            search={true}
            columns={columns}
            data={entries}
            exportCSV
            wrapperClasses="table-responsive"
          >
            {({ baseProps, searchProps, csvProps }) => (
              <>
              <div className="d-flex justify-content-end align-items-center mb-2">
                  <div className="d-flex ml-auto my-3">
                      <ButtonGroup>
                          <ExportCSV
                            variant="outline-secondary" 
                            size="sm" 
                            className="px-3"
                            { ...csvProps }
                          >
                              Export CSV
                          </ExportCSV>
                          <ExportCSV
                            variant="outline-secondary" 
                            size="sm" 
                            className="px-3"
                          >
                            Export PDF
                          </ExportCSV>
                      </ButtonGroup>
                  </div>
              </div>
              <Paginator.PaginationProvider pagination={
                Pagination({
                  custom: true,
                  showTotal: true,
                  alwaysShowAllBtns: true,
                  totalSize: entries.length,
                  paginationTotalRenderer: customTotal,
                  sizePerPageRenderer: customSizePerPage
                })
              }>
                {({ paginationProps, paginationTableProps }) => (
                  <Card>
                    <div className="table-responsive py-2">
                      <Card.Header>
                        <Row>
                          <Col xs={12} md={6} className="py-1">
                            <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
                          </Col>
                          <Col xs={12} md={6} className="d-flex justify-content-md-end py-1">
                            <Search.SearchBar {...searchProps} />
                          </Col>
                        </Row>
                      </Card.Header>
        
                      <BootstrapTable
                        reference={reference} 
                        bootstrap4
                        {...baseProps} 
                        {...paginationTableProps} 
                        bodyClasses="border-1" 
                        rowClasses="border-bottom"
                        classes="table-bordered dataTable-table table-striped"
                      />
        
                      <Card.Footer>
                        <Row>
                          <Col xs={12} md={6} className="d-flex align-items-center py-1">
                            <Paginator.PaginationTotalStandalone {...paginationProps} />
                          </Col>
                          <Col xs={12} md={6} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                            <Paginator.PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </Card.Footer>
                    </div>
                  </Card>
                )}
              </Paginator.PaginationProvider>
              </>
            )}
            
          </ToolkitProvider>

        </>
      );
}

export default DeclineInvestorTable