
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { refresh_profile } from '../../redux/actions/authActions';
import { Row, Col, Card, Button, ButtonGroup } from '@themesberg/react-bootstrap';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import Meta from '../../components/Meta';

const Profile = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const profiles = useSelector((state) => state.profiles)
    const { profile } = profiles;
    
    React.useEffect(() => {
        dispatch(refresh_profile(params.id))
    },[dispatch, params.id]);

    return (
        <section className="section">
            <Meta title="Your profile information" />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                            <div className="title mb-30">
                                <h2>Profile</h2>
                            </div>
                        </div>
                        <div
                            style={{
                                paddingBottom: "25px"
                            }}
                        >
                        </div>
                    </div>
                </div>

                {/* Profile information Informations */}
                <div id='divToPrint'>
                { 
                    profile && (
                        <div className='row'>
                            <Col lg={12}>
                                <Card border='light' className='p-0 mb-4'>
                                    <Card.Body className='pb-5'>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="input-style-1">
                                                    <label>Name</label>
                                                    <input 
                                                        type="text" 
                                                        placeholder="Name"
                                                        value={profile.name}
                                                        disabled 
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="input-style-1">
                                                    <label>Email</label>
                                                    <input 
                                                        type="email" 
                                                        placeholder="Email" 
                                                        disabled 
                                                        value={profile.email}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="input-style-1">
                                                    <label>Address</label>
                                                    <input type="text" placeholder="Address" disabled />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="input-style-1">
                                                    <label>Phone</label>
                                                    <input 
                                                        type="text" 
                                                        placeholder="Phone" 
                                                        disabled 
                                                        value={profile.phone}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="input-style-1">
                                                    <label>Broker Code</label>
                                                    <input type="text" placeholder="Account Type" value={profile.brokerCode} disabled />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="input-style-1">
                                                    <label>Account Status</label>
                                                    <input type="text" placeholder="Account Status" value={profile.investorStatus} disabled />
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* Submit Button */}
                                        <Row>
                                            <div className="col-6"></div>
                                            <div className="col-12 col-md-6">
                                                <div
                                                    className="button-group d-flex justify-content-center flex-wrap"
                                                >
                                                    <button
                                                        className="main-btn primary-btn btn-hover w-100 text-center mt-3"
                                                        style={{ backgroundColor: "#252063", color: "#ffffff" }}
                                                    >
                                                        Update Profile
                                                    </button>
                                                </div>
                                            </div>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </div>
                    )
                }
                </div>
            </div>
        </section>
    )  
}

export default Profile