import * as types from '../types/index'
import axios from 'axios';
import toast from 'react-hot-toast'
import { refresh_profile, broker_logout } from './authActions';
import { authHeader } from "../headers"


export const contact_support = ( datas ) => async (dispatch, getState) => {

    const url = "http://46.101.77.241/api/v1";

    try {
        dispatch( { type : types.SEND_MESSAGE_REQUEST });

        const {
            auth: { detail }
        } = getState();

        const getData = window.localStorage.getItem("devoper");

        const { data } = await axios.post(`${url}/users/contact-support?application=${getData}`, datas, { 
            headers: authHeader(detail.token)
         });

        if ( data ) {
            dispatch({ type: types.SEND_MESSAGE_SUCCESS, payload: data });
            toast.success(`Message has been sent.`, {                                                                         
                position: 'top-right',
            });
        };
    } catch ( error ) {
        const message = error.response.data.error
            ? error.response.data.error
            : "Something went wrong";
        if(message === "Bearer token failed verification") {
            dispatch(broker_logout())
        }
        dispatch({ type: types.SEND_MESSAGE_FAIL, payload: message });
        toast.error( message, { position: "top-right " })
    }

};

export const get_contact_messages = () => async (dispatch, getState) => {

    const url = "http://46.101.77.241/api/v1";

    try {
        dispatch( { type : types.GET_MESSAGE_REQUEST });

        const {
            auth: { detail }
        } = getState();

        const getData = window.localStorage.getItem("devoper");

        const { data } = await axios.get(`${url}/users/contact-support?application=${getData}`, { 
            headers: authHeader(detail.token)
         });

        if ( data ) {
            dispatch({ type: types.GET_MESSAGE_SUCCESS, payload: data });
        };
    } catch ( error ) {
        console.log(error)
        const message = error.response.data.error
            ? error.response.data.error
            : "Something went wrong";
        if(message === "Bearer token failed verification") {
            dispatch(broker_logout())
        }
        dispatch({ type: types.GET_MESSAGE_FAIL, payload: message });
        toast.error( message, { position: "top-right " })
    }
}