import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Tab, Tabs } from '@themesberg/react-bootstrap';
import DataTable from '../../components/Tables/DataTable';
import CorporateInvestorTable from '../../components/Tables/CorporateInvestorTable';
import Meta from '../../components/Meta';
import { get_all_individual_investors } from '../../redux/actions/investorsActions';
import Preloader from '../../components/Preloader';

const ExistingInvestors = () => {
    const dispatch = useDispatch();
    const tableRef = React.useRef();

    const [ existingInvestors, setExistingInvestors ] = React.useState(null);

    const auth = useSelector((state) => state.auth)
    const { detail } = auth;

    const individual = useSelector((state) => state.individual);
    const { loading, indivInvestor } = individual;

    const corporate = useSelector((state) => state.corporate);
    const { loading: loadingCorp, corpInvestor } = corporate;

    // Filter Individual Investors with chn number
    const getExistingInvestors = () => {
        if(indivInvestor && indivInvestor.length > 0){
            return indivInvestor.filter(investor => (
                investor?.isExisting === true && 
                investor?.accountType === "individual" &&
                (investor?.investorStatus === 1 || investor?.investorStatus === 2) &&
                (investor?.brokerCode === detail?.brokerCode || investor?.brokercontactname === detail?.brokerCode)
            ));
        };
    };

    // Filter Corporate Investors with chn number and account number
    const getCorporateInvestors = () => {
        if(corpInvestor && corpInvestor.length > 0){
            return corpInvestor.filter(investor => (
                investor?.isExisting === true && 
                investor?.accountType === "corporate" &&
                (investor?.investorStatus === 1 || investor?.investorStatus === 2) &&
                (investor?.brokerCode === detail?.brokerCode || investor?.brokercontactname === detail?.brokerCode)
            ))
        }
    };

    const indiv = getExistingInvestors();
    const corp = getCorporateInvestors();

    React.useEffect(() => {
        dispatch(get_all_individual_investors());

        setExistingInvestors(getExistingInvestors())
    }, [ dispatch ]);
    
    return (
        <section className="section">
            <Meta title="All existing investors." />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="title mb-30">
                                <h2>Existing Investors</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                                
                        </div>
                    </div>
                </div>
                <Tabs
                    defaultActiveKey="individual"
                    className="mb-3"
                >
                    <Tab eventKey="individual" title="Individual Investors">
                        {
                            loading ?
                                (
                                    <Preloader />
                                )   : (
                                    <Row>
                                        <Col lg={12}>
                                            <Card border='light' className='p-0 mb-4'>
                                                <Card.Body>
                                                    {
                                                        indiv &&
                                                            indiv.length > 0 ? (
                                                                <DataTable entries={indiv} reference={tableRef} /> 
                                                            ): (<h6
                                                                style={{ color: "#252063" }}
                                                            >You do not have any individual investor.</h6>)
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                        }
                    </Tab>
                    <Tab eventKey="corporate" title="Corporate Investors">
                        {
                            loadingCorp ?
                                (
                                    <Preloader />
                                )   : (
                                    <Row>
                                        <Col lg={12}>
                                            <Card border='light' className='my-5 '>
                                                <Card.Body>
                                                    {
                                                        corp &&
                                                            corp.length > 0 ? (
                                                                <CorporateInvestorTable entries={corp} reference={tableRef} /> 
                                                            ) : (<h6 style={{ color: "#252063" }}>You do not have any cooperate investor.</h6>)
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                        }
                    </Tab>
                </Tabs>
            </div>
        </section>
    )
}

export default ExistingInvestors