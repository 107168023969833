import { Row, Col } from "@themesberg/react-bootstrap";

const ConfirmPasswordInputField = ({
    handleValidation, 
    handlePasswordChange, 
    confirmPasswordValue, 
    confirmPasswordError
}) => {
    return (
        <div style={{ width: "100%"}}>
            <Row>
                <Col lg={12}>
                    <div className="input-style-1">
                        <label>Confirm New Password</label>
                        <input 
                            type="password" 
                            value={confirmPasswordValue}  
                            onChange={handlePasswordChange} 
                            onKeyUp={handleValidation} 
                            name="confirmPassword" 
                            placeholder="Confirm Password" 
                        />
                        <p style={{ fontSize: ".6rem" }} 
                            className="text-danger"
                        >{confirmPasswordError}</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default ConfirmPasswordInputField;