import * as types from '../types/index'
import axios from 'axios';
import toast from 'react-hot-toast'
import { refresh_profile, broker_logout } from './authActions';
import { authHeader } from "../headers"


export const settlement_account = ( datas, id ) => async (dispatch, getState) => {
    const url = "http://46.101.77.241/api/v1";

    try {

        dispatch( { type : types.SETTLEMENT_REQUEST });

        const {
            auth: { detail }
        } = getState();

        const getData = window.localStorage.getItem("devoper");

        const { data } = await axios.put(`${url}/users/settlement-account/${id}?application=${getData}`, datas, { 
            headers: authHeader(detail.token)
         });

        if ( data ) {

            console.log(data.id, "refreah")
            dispatch({ type: types.SETTLEMENT_SUCCESS, payload: data });
            dispatch(refresh_profile(data.id));
            toast.success(`Settlement account has been submitted.`, {                                                                         
                position: 'top-right',
            });
        };
    } catch ( error ) {
        const message = error.response.data.error
            ? error.response.data.error
            : "Something went wrong";
        if(message === "Bearer token failed verification") {
            dispatch(broker_logout())
        }
        dispatch({ type: types.SETTLEMENT_FAIL, payload: message });
        toast.error( message, { position: "top-right " })
    }

}