import React from 'react';
import  { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import AllTradesTable from '../../components/Tables/AllTradesTable';
import Meta from '../../components/Meta';
import { 
    admin_all_trades_request
} from '../../redux/actions/tradesAction';
import Preloader from '../../components/Preloader';

const AdminTradeRequests = () => {
    const dispatch = useDispatch();

    const allTrades = useSelector((state) => state.allTrades);
    const { loading, adminViewTrades } = allTrades;

    React.useEffect(() => {
        dispatch(admin_all_trades_request());
    }, []);

    return (
        <>
            <Meta title='All Trade Requests For A Broker' />
            <section className="section">
                <div className="container-fluid">
                    <div className="title-wrapper pt-30">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="title mb-30">
                                    <h2>All Trade Requests</h2>
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </div>
                    {
                        loading ?
                            (
                                <Preloader />
                            )   : (
                                <Row>
                                    <Col lg={12}>
                                        <Card border='light' className='p-0 mb-4'>
                                            <Card.Body className='pb-5'>
                                                {
                                                    adminViewTrades && 
                                                        adminViewTrades.length > 0 && (
                                                            <AllTradesTable entries={adminViewTrades} />
                                                        )
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            ) 
                    }
                </div>
            </section>
        </>
    )
}

export default AdminTradeRequests