export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const CREATE_BROKER_REQUEST = "CREATE_BROKER_REQUEST";
export const CREATE_BROKER_SUCCESS = "CREATE_BROKER_SUCCESS";
export const CREATE_BROKER_FAIL = "CREATE_BROKER_FAIL";
export const CREATE_BROKER_RESET = "CREATE_BROKER_LOGOUT";

export const GET_BROKER_PROFILE_REQUEST = 'GET_BROKER_PROFILE_REQUEST'
export const GET_BROKER_PROFILE_SUCCESS = 'GET_BROKER_PROFILE_SUCCESS'
export const GET_BROKER_PROFILE_FAIL = 'GET_BROKER_PROFILE_FAIL'
export const GET_BROKER_PROFILE_RESET = 'GET_BROKER_PROFILE_RESET'

export const GET_ALL_BROKER_REQUEST = 'GET_ALL_BROKER_REQUEST'
export const GET_ALL_BROKER_SUCCESS = 'GET_ALL_BROKER_SUCCESS'
export const GET_ALL_BROKER_FAIL = 'GET_ALL_BROKER_FAIL'
export const GET_ALL_BROKER_RESET = 'GET_ALL_BROKER_RESET'

export const GET_SINGLE_BROKER_REQUEST = 'GET_SINGLE_BROKER_REQUEST'
export const GET_SINGLE_BROKER_SUCCESS = 'GET_SINGLE_BROKER_SUCCESS'
export const GET_SINGLE_BROKER_FAIL = 'GET_SINGLE_BROKER_FAIL'
export const GET_SINGLE_BROKER_RESET = 'GET_SINGLE_BROKER_RESET'

export const CHANGE_BROKERS_PASSWORD_REQUEST = 'CHANGE_BROKERS_PASSWORD_REQUEST'
export const CHANGE_BROKERS_PASSWORD_SUCCESS = 'CHANGE_BROKERS_PASSWORD_SUCCESS'
export const CHANGE_BROKERS_PASSWORD_FAIL = 'CHANGE_BROKERS_PASSWORD_FAIL'
export const CHANGE_BROKERS_PASSWORD_RESET = 'CHANGE_BROKERS_PASSWORD_RESET'

export const BROKERS_PASSWORD_RECOVERY_REQUEST = 'BROKERS_PASSWORD_RECOVERY_REQUEST'
export const BROKERS_PASSWORD_RECOVERY_SUCCESS = 'BROKERS_PASSWORD_RECOVERY_SUCCESS'
export const BROKERS_PASSWORD_RECOVERY_FAIL = 'BROKERS_PASSWORD_RECOVERY_FAIL'
export const BROKERS_PASSWORD_RECOVERY_RESET = 'BROKERS_PASSWORD_RECOVERY_RESET'
