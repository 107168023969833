import * as types from "../types/index"

export const messageReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case types.SEND_MESSAGE_REQUEST:
            return { ...state, loading: true };
        case types.SEND_MESSAGE_SUCCESS:
            return { loading: false, sent: action.payload };
        case types.SEND_MESSAGE_FAIL:
            return { loading: false, error: action.payload };
        case types.SEND_MESSAGE_RESET:
            return {};
        default:
            return state;
    }
}

export const getMessageReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case types.GET_MESSAGE_REQUEST:
            return { ...state, loading: true };
        case types.GET_MESSAGE_SUCCESS:
            return { loading: false, messages: action.payload };
        case types.GET_MESSAGE_FAIL:
            return { loading: false, error: action.payload };
        case types.GET_MESSAGE_RESET:
            return {};
        default:
            return state;
    }
}