import * as types from "../types/index"

export const settlementReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case types.SETTLEMENT_REQUEST:
            return { ...state, loading: true };
        case types.SETTLEMENT_SUCCESS:
            return { loading: false, settlements: action.payload };
        case types.SETTLEMENT_FAIL:
            return { loading: false, error: action.payload };
        case types.SETTLEMENT_RESET:
            return {};
        default:
            return state;
    }
}