import React from 'react';
import  { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import OrdersTable from '../../components/Tables/OrdersTable';
import Meta from '../../components/Meta';
import {
    get_investor_trade_orders
} from "../../redux/actions/investorsActions"
import Preloader from '../../components/Preloader';

const AllOrders = () => {
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth)
    const { detail } = auth;

    const tradeOrders = useSelector((state) => state.tradeOrders);
    const { loading, brokerOrders } = tradeOrders;

    const sortingOrders = brokerOrders && brokerOrders.reverse()

    React.useEffect(() => {

        dispatch(get_investor_trade_orders(detail?.brokerCode));
    }, []);

    return (
        <>
            <Meta title='All Trade Requests For A Broker' />
            <section className="section">
                <div className="container-fluid">
                    <div className="title-wrapper pt-30">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="title mb-30">
                                    <h2>All Trade Orders</h2>
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </div>

            
                    {
                        loading ?
                            (
                                <Preloader />
                            )   : (
                                <Row>
                                    <Col lg={12}>
                                        <Card border='light' className='p-0 mb-4'>
                                            <Card.Body className='pb-5'>
                                                {
                                                    brokerOrders && 
                                                        brokerOrders.length > 0 ? (
                                                            <OrdersTable entries={sortingOrders} />
                                                        ) : (
                                                            <h6
                                                                style={{ color: "#252063" }}
                                                            >You do not have a trade order.</h6>
                                                        )
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            ) 
                    }
                </div>
            </section>
        </>
    )
}

export default AllOrders