import { combineReducers } from "redux";

import {
    authReducer,
    brokerProfileReducer,
    getBrokersReducer,
    getSingleBrokersReducer,
    changePasswordReducer,
    brokerPasswordREcoveryReducer
} from "./authReducer"

import {
    policyReducer
} from "./policyReducer"

import {
    settlementReducer
} from "./settlementReducer";

import { messageReducer,
    getMessageReducer
} from "./messageReducer";

import {
    getIndivInvestorsReducer,
    singleIndivInvestorsReducer,
    getCorpInvestorsReducer,
    confirmInvestorAccountReducer,
    updateInvestorStatusReducer,
    declinedInvestorsReducer,
    getAllDeclinedInvestorReducer,
    getSingleDeclinedInvestorReducer,
    getAllTradeOrderReducer
} from "./investorsReducer"

import { 
    investorTradesReducer,
    investorSellReducer,
    adminViewTradesReducer
} from './tradesReducer'

const reducer = combineReducers({
    auth: authReducer,
    brokerPassword: changePasswordReducer,
    getBroker: getSingleBrokersReducer,
    broker: getBrokersReducer,
    profiles: brokerProfileReducer,
    policy: policyReducer,
    settlement: settlementReducer,
    message: messageReducer,
    brokerMessages: getMessageReducer,
    individual: getIndivInvestorsReducer,
    singleIndv: singleIndivInvestorsReducer,
    corporate: getCorpInvestorsReducer,
    accountNumber: confirmInvestorAccountReducer,
    trades: investorTradesReducer,
    saleOrder: investorSellReducer,
    allTrades: adminViewTradesReducer,
    status: updateInvestorStatusReducer,
    decline: declinedInvestorsReducer,
    declinedInvestors: getAllDeclinedInvestorReducer,
    singleDeclined: getSingleDeclinedInvestorReducer,
    tradeOrders: getAllTradeOrderReducer,
    brokerRecovery: brokerPasswordREcoveryReducer
})


export default reducer;