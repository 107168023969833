import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card } from '@themesberg/react-bootstrap'
import { get_single_declined_investors, get_single_individual_investors } from '../../redux/actions/investorsActions';
import Meta from '../../components/Meta';


const DeclinedInvestor = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const singleDeclined = useSelector((state) => state.singleDeclined)
    const { singleDeclinedInvestors } = singleDeclined;

    const singleIndv = useSelector((state) => state.singleIndv)
    const { singleIndivInvestor } = singleIndv;

    React.useEffect(() => {
        if(singleIndivInvestor && singleIndivInvestor.id === singleDeclinedInvestors?.investorId){
            dispatch(
                get_single_declined_investors(singleDeclinedInvestors?.investorId),
                get_single_individual_investors(singleIndivInvestor.id)
            );
        } 
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(get_single_individual_investors(params.id))
        dispatch(get_single_declined_investors("639ab2999a7411754c0af2a8"),)
    }, [params.id]);

    return (
        <section className="section">
            <Meta title="Create a new broker account." />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title mb-30">
                                <h2>Declined Investor</h2>
                            </div>
                        </div>
                        <div
                            className='col-md-6'
                        >
                        </div>
                    </div>
                </div>
                {/* Brokers Informations */}
                <Row>
                    <Col lg={12}>
                        <Card border='light' className='p-0 mb-4'>
                            <Card.Body className='pb-5'>
                                <Row>
                                    <Col lg={6}>
                                        <div className="input-style-1">
                                            <label>Name</label>
                                            <input 
                                                type="text" 
                                                placeholder="Name" 
                                                value={singleIndivInvestor?.fullName}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="input-style-1">
                                            <label>Email</label>
                                            <input 
                                                type="email" 
                                                placeholder="Email" 
                                                value={singleIndivInvestor?.emailAddress
                                                }
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="input-style-1">
                                            <label>Phone</label>
                                            <input 
                                                type="number" 
                                                placeholder="Phone" 
                                                value={singleIndivInvestor?.phoneNumber} 
                                                disabled 
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="input-style-1">
                                            <label>Reason For Trade Decline</label>
                                            <textarea 
                                                type="text" placeholder="Reasons for declining this investor will be here."
                                                disabled
                                                value={singleDeclinedInvestors?.reason}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default DeclinedInvestor;