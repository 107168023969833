import * as types from "../types/index"

export const policyReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case types.POLICY_REQUEST:
            return { ...state, loading: true };
        case types.POLICY_SUCCESS:
            return { loading: false, policy: action.payload };
        case types.POLICY_FAIL:
            return { loading: false, error: action.payload };
        case types.POLICY_RESET:
            return {};
        default:
            return state;
    }
}