import React, { useState } from "react"
import  { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Badge, ButtonGroup } from '@themesberg/react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ExportCSV from "../ExportCSV";
import ModalComponent from '../ModalComponent';
import { decline_investor } from "../../redux/actions/investorsActions";

const CorporateInvestorTable = ({ entries, reference }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [ openModal, setOpenModal ] = useState(false);
    const [ reason, setReason ] = useState("")
    const [ status, setStatus ] = useState(0)
    const [ userid, setUserid ] = useState("")
    const [ showOptions, setShowOptions ] = useState(false)

    const auth = useSelector((state) => state.auth)
    const { detail } = auth;

    const toggleModal = () => {
      setOpenModal(!openModal)
    }

    const handleDecline = (e) => {
      e.preventDefault();

      const data = {
        reason, 
        status,
        investorId: userid,
        brokerId: detail.id
      };
      dispatch(decline_investor(data));
      setOpenModal(false);
    };

    const actionBtn = (cell) => {
        return (
          <div className="dropdow">
            <span style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
              <Link to={`/dashboard/investor/${cell}`}><FontAwesomeIcon icon={faEye} className="me-2" /> </Link>
              <Link to="#" className="text-danger" onClick={() => {
                      setOpenModal(true) 
                      setUserid(cell)
                    }}>
                    <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> 
                  </Link>
            </span>
            {/* {
              showOptions && ( */}
                <div className="dropdown-content">
                  <Link to={`/dashboard/investor/${cell}`}><FontAwesomeIcon icon={faEye} className="me-2" /> View Details</Link>
                  <Link to="#" className="text-danger" onClick={() => {
                      setOpenModal(true) 
                      setUserid(cell)
                    }}>
                    <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Decline
                  </Link>
                </div>
              {/* )
            } */}
          </div>
        )
    }

    const columns = [
        { dataField: "id", text: "ID", hidden: true },
        { dataField: "rcNo", text: "RC Number", sort: true},
        { dataField: "accountName", text: "Account Name", sort: true },
        { dataField: "contactEmailAddress", text: "Email", sort: true },
        { dataField: "contactPhoneNumber", text: "Phone", sort: true },
        { dataField: "brokername", text: "Broker Name", sort: true },
        { dataField: "brokerphone", text: "Broker Phone", sort: true },
        { dataField: "investorStatus", text: "Status", formatter: (cell) => (
            cell === 1 ?
                <Badge style={{ backgroundColor: "#f2be30"}}>pending</Badge> :
            cell === 2 ?
                <Badge style={{ backgroundColor: "#11bf43"}}>approved</Badge> :
                <Badge style={{ backgroundColor: "#ec2329"}}>declined</Badge>
        )},
        { dataField: "id", text: "Actions", formatter: (cell) =>  actionBtn(cell)},
      ];
      
      const customTotal = (from, to, size) => (
        <div>
          Showing {from} to {to} of {size} entries
        </div>
      );
      
      const customSizePerPage = (props) => {
        const { options, currentSizePerPage, onSizePerPageChange } = props;
      
        const onPageChange = (e) => {
          const page = e.target.value;
          onSizePerPageChange(page);
        }
      
        return (
          <Row>
            <Col xs="auto">
              <Form.Select value={currentSizePerPage} onChange={onPageChange} className="pe-5">
                {options.map(o => (
                  <option key={o.page} value={o.page}>
                    {o.text}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              entries per page
            </Col>
          </Row>
        );
      };
      
      return(
        <>
          <ToolkitProvider
            keyField="id"
            search={true}
            columns={columns}
            data={entries}
            exportCSV
            wrapperClasses="table-responsive"
          >
            {({ baseProps, searchProps, csvProps }) => (
              <>
              <div className="d-flex justify-content-end align-items-center mb-2">
                  <div className="d-flex ml-auto my-3">
                      <ButtonGroup>
                          <ExportCSV
                            variant="outline-secondary" 
                            size="sm" 
                            className="px-3"
                            { ...csvProps }
                          >
                              Export CSV
                          </ExportCSV>
                          <ExportCSV
                            variant="outline-secondary" 
                            size="sm" 
                            className="px-3"
                          >
                            Export PDF
                          </ExportCSV>
                      </ButtonGroup>
                  </div>
              </div>
              <Paginator.PaginationProvider pagination={
                Pagination({
                  custom: true,
                  showTotal: true,
                  alwaysShowAllBtns: true,
                  totalSize: entries.length,
                  paginationTotalRenderer: customTotal,
                  sizePerPageRenderer: customSizePerPage
                })
              }>
                {({ paginationProps, paginationTableProps }) => (
                  <Card>
                    <div className="table-responsive py-2">
                      <Card.Header>
                        <Row>
                          <Col xs={12} md={6} className="py-1">
                            <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
                          </Col>
                          <Col xs={12} md={6} className="d-flex justify-content-md-end py-1">
                            <Search.SearchBar {...searchProps} />
                          </Col>
                        </Row>
                      </Card.Header>
        
                      <BootstrapTable
                        reference={reference} 
                        bootstrap4
                        {...baseProps} 
                        {...paginationTableProps} 
                        bodyClasses="border-1" 
                        rowClasses="border-bottom"
                        classes="table-bordered dataTable-table table-striped"
                      />
        
                      <Card.Footer>
                        <Row>
                          <Col xs={12} md={6} className="d-flex align-items-center py-1">
                            <Paginator.PaginationTotalStandalone {...paginationProps} />
                          </Col>
                          <Col xs={12} md={6} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
                            <Paginator.PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </Card.Footer>
                    </div>
                  </Card>
                )}
              </Paginator.PaginationProvider>
              </>
            )}
            
          </ToolkitProvider>

          {/* <Modal /> */}
          <ModalComponent
            open={openModal}
            title={`Decline New Investor`}
            toggle={toggleModal}
          >
              <form>
                <label 
                  style={{ fontSize: '0.8rem'}}
                  className="form-label"
                >Please provide a reason for this decline.</label>
                <textarea 
                  className="form-control"
                  style={{
                    fontSize: ".8rem",
                    minHeight: "100px"
                  }}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
                <div className="text-right my-3 d-flex justify-content-end">
                  <button 
                    className="btn btn-danger"
                    onClick={handleDecline}
                    style={{
                      fontSize: ".8rem"
                    }}
                    disabled={reason.length < 5 ? true : false}
                  >Decline</button>
                </div>
              </form>
          </ModalComponent>
        </>
      );
}

export default CorporateInvestorTable