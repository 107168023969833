import React from "react";

const Preloader = () => {
	return (
		<div className="preloader">
			<span className="Loader  Loader--fill  Loader--lg  is-pulsing"></span>
		</div>
	);
};

export default Preloader;