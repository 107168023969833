export const ALL_INVESTOR_TRADE_REQUEST = "ALL_INVESTOR_TRADE_REQUEST";
export const ALL_INVESTOR_TRADE_SUCCESS = "ALL_INVESTOR_TRADE_SUCCESS";
export const ALL_INVESTOR_TRADE_FAIL = "ALL_INVESTOR_TRADE_FAIL";
export const ALL_INVESTOR_TRADE_RESET = "ALL_INVESTOR_TRADE_RESET";

export const ALL_INVESTOR_SELL_TRADE_REQUEST = "ALL_INVESTOR_SELL_TRADE_REQUEST";
export const ALL_INVESTOR_SELL_TRADE_SUCCESS = "ALL_INVESTOR_SELL_TRADE_SUCCESS";
export const ALL_INVESTOR_SELL_TRADE_FAIL = "ALL_INVESTOR_SELL_TRADE_FAIL";
export const ALL_INVESTOR_SELL_TRADE_RESET = "ALL_INVESTOR_SELL_TRADE_RESET";

export const ADMIN_INVESTOR_TRADE_REQUEST = "ADMIN_INVESTOR_TRADE_REQUEST";
export const ADMIN_INVESTOR_TRADE_SUCCESS = "ADMIN_INVESTOR_TRADE_SUCCESS";
export const ADMIN_INVESTOR_TRADE_FAIL = "ADMIN_INVESTOR_TRADE_FAIL";
export const ADMIN_INVESTOR_TRADE_RESET = "ADMIN_INVESTOR_TRADE_RESET";
