import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import { contact_support } from '../../redux/actions/messageAction';
import Meta from '../../components/Meta';

const Contact = () => {

    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth)
    const { detail } = auth;
    const [ name, setName ] = React.useState(detail.name);
    const [ email, setEmail ] = React.useState(detail.email);
    const [ title, setTitle ] = React.useState("");
    const [ message, setMessage ] = React.useState("");

    const submitBrokerMessage = () => {
        const datas = {
            name,
            email,
            title,
            message
        };
        dispatch(contact_support(datas));
        setName("");
        setEmail("");
        setTitle("");
        setMessage("");
    }

    return (
        <section className="section">
            <Meta title="Contact NASD Support." />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title mb-30">
                                <h2>Contact NASD Support.</h2>
                            </div>
                        </div>
                        <div
                            className='col-md-6'
                        >
                            
                        </div>
                    </div>
                </div>

                {/* Brokers Informations */}
                <Row>
                    <Col lg={6}>
                        <Card border='light' className='p-0 mb-4'>
                            <Card.Body className='pb-5'>
                                <Row>
                                    <Col lg={12}>
                                        <div className="input-style-1">
                                            <label>Title</label>
                                            <input 
                                                type="text" 
                                                placeholder="Title" 
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="input-style-1">
                                            <label>Message</label>
                                            <textarea 
                                                type="text" 
                                                placeholder="message" 
                                                rows={10}
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div
                                            className="
                                                button-group
                                                d-flex
                                                justify-content-center
                                                flex-wrap
                                            "
                                        >
                                            <button
                                                className="main-btn primary-btn btn-hover w-100 text-center"
                                                style={{ backgroundColor: "#252063", color: "#ffffff"}}
                                                onClick={submitBrokerMessage}
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Contact