import * as types from '../types/index'
import { authHeader } from '../headers';
import { broker_logout } from './authActions';
import axios from 'axios';
import toast from 'react-hot-toast'

export const get_single_broker_buy_trades = (code) => async (dispatch, getState) => {
    const url = "https://apis.nasdotcng.com/api/broker-trades";
    let datas

    try {
        dispatch( { type : types.ALL_INVESTOR_TRADE_REQUEST });

        const {
			auth: { detail },
		} = getState();

        console.log(detail.brokerCode, "Admin Resent Details")

        const { data } = await axios.get(`${url}/${code}`, {
			headers: authHeader("ileyvcbi8k7262o82l8y3h20o8d3yv2o3723g"),
		});

        const filteredData = () => {
            if(data && data.length > 0){
                return data.filter(d => d["FROM MEMBER"] === code )
            };
        };

        datas = filteredData();

        if ( datas ) {
            dispatch({ type: types.ALL_INVESTOR_TRADE_SUCCESS, payload: datas });
        };
        
    } catch ( error ) {
        const message = error.response.data.error
            ? error.response.data.error
            : "Something went wrong";
        if(message === "Bearer token failed verification") {
            dispatch(broker_logout())
        }
        dispatch({ type: types.ALL_INVESTOR_TRADE_FAIL, payload: message });
        toast.error( message, { position: "top-right " })
    };

}

export const get_single_broker_sell_trades = (code) => async (dispatch, getState) => {
    const url = "https://apis.nasdotcng.com/api/broker-trades";

    let datas

    try {
        dispatch( { type : types.ALL_INVESTOR_SELL_TRADE_REQUEST });

        const {
			auth: { detail },
		} = getState()

        const { data } = await axios.get(`${url}/${code}`, {
			headers: authHeader("ileyvcbi8k7262o82l8y3h20o8d3yv2o3723g"),
		});

        const filteredData = () => {
            if(data && data.length > 0){
                return data.filter(d => d["TO MEMBER"] === code)
            }
        };

        datas = filteredData();

        if ( datas ) {
            dispatch({ type: types.ALL_INVESTOR_SELL_TRADE_SUCCESS, payload: datas });
        };
        
    } catch ( error ) {
        const message = error.response.data.error
            ? error.response.data.error
            : "Something went wrong";
        if(message === "Bearer token failed verification") {
            dispatch(broker_logout())
        }
        dispatch({ type: types.ALL_INVESTOR_SELL_TRADE_FAIL, payload: message });
        toast.error( message, { position: "top-right " })
    }

}

export const admin_all_trades_request = () => async (dispatch, getState) => {
    const url = "https://apis.nasdotcng.com/api/admin-trades";

    try {
        dispatch( { type : types.ADMIN_INVESTOR_TRADE_REQUEST });

        const {
			auth: { detail },
		} = getState();

        const { data } = await axios.get(`${url}`, {
			headers: authHeader("ileyvcbi8k7262o82l8y3h20o8d3yv2o3723g"),
		});

        if ( data ) {
            dispatch({ type: types.ADMIN_INVESTOR_TRADE_SUCCESS, payload: data });
        };
        
    } catch ( error ) {
        const message = error.response.data.error
            ? error.response.data.error
            : "Something went wrong";
        if(message === "Bearer token failed verification") {
            dispatch(broker_logout())
        }
        dispatch({ type: types.ADMIN_INVESTOR_TRADE_FAIL, payload: message });
        toast.error( message, { position: "top-right " })
    };

}