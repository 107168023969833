import React from 'react';
import { Row, Col, Card, ButtonGroup, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import Meta from '../../components/Meta';
import { get_single_broker } from '../../redux/actions/authActions';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

const SingleTradeRequest = () => {

    const params = useParams();
    const dispatch = useDispatch();
    const tradeName = "20220609-13043"
    const printDocument = () => {
        htmlToImage.toPng(document.getElementById('divToPrint'), { quality: 1.00 })
        .then(function (dataUrl) {
            let link = document.createElement('a');
            link.download = 'my-image-name.jpeg';
            const pdf = new jsPDF();
            const imgProps= pdf.getImageProperties(dataUrl);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`${tradeName}.pdf`); 
        }); 
    };

    React.useEffect(() => {
        dispatch(get_single_broker(params.id)); 
    }, [dispatch, params.id])

    return (
        <section className="section">
            <Meta title="Single users trade request." />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                            <div className="title mb-30">
                                <h2>Trade Information</h2>
                            </div>
                        </div>
                        <div
                            style={{
                                paddingBottom: "25px"
                            }}
                        >
                            <ButtonGroup>
                                <Button
                                    variant="outline-secondary" 
                                    size="sm" 
                                    className="px-3"
                                    onClick={() => printDocument()}
                                >
                                    Export PDF
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>

                {/* Trade Informations */}
                <div id="divToPrint">
                    <Row>
                        <Col lg={12}>
                            <Card border='light' className='p-0 mb-4'>
                                <Card.Body className='pb-5'>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="input-style-1">
                                                <label>External Ticket</label>
                                                <input type="text" placeholder="External Ticket" disabled />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="input-style-1">
                                                <label>From Member</label>
                                                <input type="email" placeholder="From Member" disabled />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="input-style-1">
                                                <label>From Account</label>
                                                <input type="text" placeholder="From Account" disabled />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="input-style-1">
                                                <label>Symbol</label>
                                                <input type="text" placeholder="Symbol" disabled />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="input-style-1">
                                                <label>Volume</label>
                                                <input type="text" placeholder="Volume" disabled />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="input-style-1">
                                                <label>Price</label>
                                                <input type="text" placeholder="Price" disabled />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </section>    
    )
}

export default SingleTradeRequest;